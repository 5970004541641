<template>
  <div class="employee">
    <header>
      <div class="back" @click="back">
        <UIcon name="arrow-left" />
      </div>
      <div class="title">{{ employee.surname }} {{ employee.name }}</div>
    </header>

    <div class="employee-info p-6 mb-6">
      <header class="!mb-8">
        <div class="title !font-semibold">Сотрудник</div>
      </header>

      <div class="wrapper">
        <div class="avatar">{{ employee.initials }}</div>
        <div class="fields">
          <div class="flex pb-10">
            <Input
              v-model="employee.surname"
              id="surname"
              placeholder="Фамилия"
              class="mr-6"
            />
            <Input
              v-model="employee.name"
              id="name"
              placeholder="Имя"
            />
          </div>
          <div class="flex pb-10">
            <Input
              v-model="employee.email"
              id="email"
              placeholder="Email*"
              class="mr-6"
            />
            <Input
              v-model="employee.phone"
              id="phone"
              placeholder="Телефон"
            />
          </div>
          <div class="flex pb-12">
            <Input
                v-model="employee.password"
                id="password"
                type="password"
                placeholder="Пароль*"
              />
          </div>
          <div class="flex justify-end">
            <UButton v-if="employee.email" @click="isSendPassword = true" variant="yellow" class="mr-4">Отправить пароль</UButton>
            <UButton @click="deleteEmployee" variant="soft" class="mr-4">Удалить сотрудника</UButton>
            <UButton @click="changeEmployee">Сохранить</UButton>
          </div>
        </div>
      </div>
    </div>

    <ProgressList class="mb-6" :courses="progressCourses" :progress="progress" />
    <EmployeesHistory class="mb-6" />

    <UModal v-model="isSendPassword" width="400px">
      <div class="send-password text-center py-1 px-4">
        <div class="text-2xl !font-medium !mb-2">Отправить пароль на</div>
        <p>{{ employee.email }}</p>

        <div class="flex justify-end !mt-4">
          <UButton @click="isSendPassword = false" variant="gray" class="mr-4">Закрыть</UButton>
          <UButton @click="sendPassword">Отправить</UButton>
        </div>
      </div>
    </UModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { coreApi } from '@/api';
import router from '@/router';
import UButton from '@/components/redesign/ui/UButton';
import UPreloader from '@/components/redesign/ui/UPreloader';
import Input from '@/components/redesign/form/FormInput';
import ProgressList from '@/components/redesign/employees/progress/ProgressList';
import EmployeesHistory from '@/components/redesign/employees/history/EmployeesHistory';
import UModal from '@/components/redesign/ui/UModal'

export default {
  name: 'Employee',
  components: {
    UButton,
    UPreloader,
    Input,
    ProgressList,
    EmployeesHistory,
    UModal,
  },
  data() {
    return {
      progress: [],
      employee: {},
      progressCourses: [],
      isSendPassword: false,
    };
  },
  computed: {
    ...mapState('main', ['user']),
    ...mapState('courses', ['employees', 'courses']),
  },
  methods: {
    async init() {
      const employee = this.employees.find((item) => item.id == router.currentRoute.params.id);
      
      if (employee) {
        this.employee = employee;
        await this.formirationCourses();
      } else {
        this.back();
      }
    },
    async formirationCourses() {
      let courses = this.courses.filter((item) => {
        const user = item.employees.find((item) => item.id == router.currentRoute.params.id)
        return user || false;
      });

      if (courses) {
        // GET PROGRESS
        const progress = await coreApi.employeProgress(router.currentRoute.params.id);
        // FORMIRATION PROGRESS IN COURSE
        courses = courses?.map((course) => {
          return {
            ...course,
            structure: course.structure.split(' | ').map((item) => {
              const data = item.split(' - ');
              const id = data[0];
              const type = data[1];

              return { id, type };
            }),
          }
        });

        this.progressCourses = courses;
        if (progress) {
          this.progress = JSON.parse(progress[0]?.progress) || [];
        }
      }
    },
    back() {
      this.$router.replace(`/employees`);
    },
    async changeEmployee() {
      const { name, email, password } = this.employee;
      if (!(name || email || password)) {
        this.$toast.error("Имя, email и пароль обязательные поля");
        return;
      }

      try {
        await coreApi.employeesUpdate([{
          ...this.employee,
          userId: this.user.id,
        }]);
        this.$toast("Профиль сотрудника успешно обновлен");
      } catch (error) {
        this.$toast.error("Обновить профиль не удалось");
      }
    },
    async deleteEmployee() {
      await coreApi.employeesDelete({ id: this.employee.id });
      this.$toast("Профиль сотрудника успешно удален");
      this.back();
    },
    async sendPassword() {
      await coreApi.sendPasswordUser(this.employee)
      this.isSendPassword = false;
    },
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
.employee {
  // max-width: 900px;

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .back {
      min-width: 48px;
      width: 48px;
      height: 48px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 24px;
      cursor: pointer;

      .icon {
        fill: #A9A9A9;
      }

      &:hover {
        .icon {
          fill: #777777;
        }
      }
    }

    & > .title {
      width: 100%;
      font-weight: 600;
      font-size: 24px;
      line-height: 18px;
    }
  }

  .employee-info {
    background: #fff;
    border-radius: 10px;
    width: 100%;

    & > header {
      .title {
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
      }
    }

    & > .wrapper {
      display: flex;

      & > .avatar {
        min-width: 124px;
        width: 124px;
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        background: #E6F3FA;
        color: #0088CC;
        margin: 0 40px 0 16px;
      }

      & > .fields {
        width: 100%;
      }

      .button {
        max-width: 210px;
      }
    }
  }

  .send-password {
    max-width: 400px;
  }
}
</style>