<template>
  <div class="progres-bar-course">
    <UProgressBar class="pb-2" :count="progressData.count" />
    <div class="counts text-silver text-sm flex items-center">
      <div class="pr-3">
        {{ `Тесты ${progressData.test.finish} из ${progressData.test.count}` }}
      </div>
      <div class="pl-3">
        {{
          `Материалы ${progressData.material.finish} из ${progressData.material.count}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UProgressBar from '@/components/redesign/ui/UProgressBar';

export default {
  name: 'ProgresBarCourse',
  props: {
    course: {
      type: Object,
      required: true,
    },
    progress: {
      type: Array,
      default: () => [],
    },
  },
  components: { UProgressBar },
  data() {
    return {
      progressData: {
        count: 0,
        test: { count: 0, finish: 0 },
        material: { count: 0, finish: 0 },
      },
    };
  },
  methods: {
    init() {
      let progressCourse = this.progress?.find(
        (item) => item.idCourse == this.course.id
      );
      progressCourse = progressCourse?.progress || [];

      for (const entity of this.course.structure) {
        if (entity.type === 'test') {
          this.progressData.test.count++;
          const data = progressCourse.find(
            (item) => item.idEntity === entity.id && item.type === entity.type
          );

          if (data) {
            this.progressData.test.finish++;
          }
        }

        if (entity.type === 'material') {
          this.progressData.material.count++;
          const data = progressCourse.find(
            (item) => item.idEntity === entity.id && item.type === entity.type
          );

          if (data) {
            this.progressData.material.finish++;
          }
        }
      }

      // Count size
      const count =
        Number(((this.progressData.test.finish + this.progressData.material.finish) /
          (this.progressData.test.count + this.progressData.material.count)) *
          100 || 0);
      this.progressData.count = count.toFixed();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.progres-bar-course {
  width: 100%;

  .counts {
    & > div:first-child {
      border-right: 1px solid #d2d2d2;
    }
  }
}
</style>
