<template>
  <div
    class="progress-course !p-4 rounded-xl"
    :class="{ active: active, silver: isHeader }"
  >
    <header
      v-if="isHeader"
      class="flex justify-between items-center cursor-pointer"
      @click="active = !active"
    >
      <div class="text-base">Курс: {{ course.title }}</div>
      <UIcon name="chevron-bottom" />
    </header>

    <ProgresBarCourse
      :course="course"
      :progress="progress"
      :class="{ active: active || !isHeader }"
    />
  </div>
</template>

<script>
import ProgresBarCourse from '@/components/redesign/base/ProgresBarCourse';

export default {
  name: 'ProgressCourse',
  components: {
    ProgresBarCourse,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss">
.progress-course {
  width: 100%;

  &.silver {
    background: #f9f9f9;
  }

  header .icon {
    transition: all 0.3s;
  }

  &.active {
    header {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  & > .progres-bar-course {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    overflow: 0;
    transition: all 0.15s;

    &.active {
      overflow: 1;
      padding-top: 1rem;
      max-height: 300px;
    }
  }
}
</style>
