<template>
  <div class="u-progress-bar flex items-center">
    <div class="progress-container mr-3">
      <div class="progress" :style="{ width: `${count}%` }"></div>
    </div>
    <div class="count">{{ count }}%</div>
  </div>
</template>

<script>
export default {
  name: 'UProgressBar',
  props: {
    count: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.u-progress-bar {
  width: 100%;

  .progress-container {
    position: relative;
    width: 100%;
    background: #e0eef5;
    border-radius: 10px;
    height: 11px;

    .progress {
      position: absolute;
      background: var(--main);
      border-radius: 10px;
      height: 11px;
    }
  }
}
</style>
