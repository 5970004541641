<template>
  <div class="progress-list bg-white rounded-xl w-full p-6">
    <div class="text-2xl-m !mb-6 !font-semibold">Прогресс сотрудника по курсам</div>
    <div v-if="courses.length">
      <ProgressCourse
        v-for="(course, index) of courses"
        :key="index"
        :course="course"
        :progress="progress"
        isHeader
        class="!mb-4"
      />
    </div>
    <div v-else class="empty !mt-12 !mb-12">
      <UIcon name="illustrations/empty-history" />
      <p class="text-silver !mt-8 text-center">Здесь будет прогресс <br /> пользователя</p>
    </div>
  </div>
</template>

<script>
import ProgressCourse from '@/components/redesign/employees/progress/ProgressCourse';

export default {
  name: 'ProgressList',
  components: { ProgressCourse },
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Array,
      default: () => [],
    }
  },
};
</script>

<style lang="scss">
.progress-list {
  width: 100%;
  box-shadow: 0px 2px 5px rgba(219, 219, 219, 0.25);

  .empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
