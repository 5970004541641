<template>
  <div class="employees-history bg-white rounded-xl w-full p-6">
    <div class="text-2xl-m !mb-4 !font-semibold">История прохождения</div>
    
    <div v-if="progress.length" class="ring-black ring-opacity-5">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="!p-4 text-left text-sm font-semibold text-gray-900">Тест</th>
            <th scope="col" class="!p-4 text-left text-sm font-semibold text-gray-900">Дата</th>
            <th scope="col" class="!p-4 text-left text-sm font-semibold text-gray-900">Правильных ответов</th>
            <th scope="col" class="!p-4 text-left text-sm font-semibold text-gray-900">Время прохождения</th>
            <th scope="col" class="!p-4 text-left text-sm font-semibold text-gray-900">Результат</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(progres, index) in progress" :key="index">
            <td class="!p-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ progres.test }}</td>
            <td class="!p-4 whitespace-nowrap text-sm text-gray-500">{{ progres.date }}</td>
            <td class="!p-4 whitespace-nowrap text-sm text-gray-500">{{ progres.rightAnswer }}</td>
            <td class="!p-4 whitespace-nowrap text-sm text-gray-500">{{ progres.time }}</td>
            <td class="!p-4 whitespace-nowrap text-sm text-gray-500">{{ progres.status.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="empty !mt-12 !mb-12">
      <UIcon name="illustrations/empty-history" />
      <p class="text-silver !mt-8 text-center">Здесь будет статистика по <br /> пользователю</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { coreApi } from '@/api'

export default {
  name: 'EmployeesHistory',
  data() {
    return {
      progress: [
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: '1lindsay.walton@example.com', role: 'Member' },
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: '2lindsay.walton@example.com', role: 'Member' },
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: '3lindsay.walton@example.com', role: 'Member' },
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: '4lindsay.walton@example.com', role: 'Member' },
      ],
      listStatus: {
        1: 'Провален',
        2: 'Пройден',
        3: 'Завершен заранее'
      },
    };
  },
  computed: {
    ...mapState('main', ['user', 'tests']),
    ...mapState(['courses']),
  },
  methods: {
    async init() {
      const employees = await coreApi.employees(this.user.id);
      let statistic = await coreApi.getStatistics(this.user.id);

      if (statistic) {
        statistic = statistic
        .filter((item) => item.user == this.$route.params.id)
        .map((item) => {
          const employee = employees.find((employee) => employee.id == item.user)
          const test = this.tests.find((test) => test.hash == item.test)
          const status = this.getStatusItem(item.status)

          return {
            employee: employee?.name || 'Сотрудник удален',
            test: test?.title || 'Тест не найден',
            date: item.dateCreate,
            rightAnswer: item.rightAnswer,
            time: this.getTime(item.passedFor),
            status
          }
        })

        this.progress = statistic.reverse();

        console.log('this.progress', this.progress);
      }
    },
    getStatusItem(idStatus) {
      let variant = 'danger'
      if (idStatus == 2) variant = 'success'
      if (idStatus == 3) variant = 'warning'

      return {
        status: this.listStatus[idStatus],
        variant
      }
    },
    getTime(timestamp) {
      let message = ''
      const hours = Math.floor(timestamp / 60 / 60)
      const minutes = Math.floor(timestamp / 60) - (hours * 60)
      const seconds = timestamp % 60

      if (hours) message += `${hours} ч `
      if (minutes) message += `${minutes} мин `
      if (seconds) message += `${seconds} сек`

      return message || '--'
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
.employees-history {
  width: 100%;
  box-shadow: 0px 2px 5px rgba(219, 219, 219, 0.25);

  .empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
  